import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Link = styled.a`
  cursor: pointer;
`;

const Button = styled.button`
  padding: 9px 53px;
  width: 175px;
`;

const RejectButton = styled.button`
  padding: 9px;
  width: 100px;
  color: #F21F1D;
  border: 1px solid #F21F1D;
  background-color: #FCF2F2;

  &:hover {
    background-color: #F21F1D;
    color: white;
    border: 1px solid #F21F1D;
  }
`;

const Td = styled.td`
  vertical-align: middle !important;
  padding: 12px !important;
  &:first-child {
    padding-left: 32px !important;
  }
  &:last-child {
    width: 1px;
    white-space: nowrap;
    padding-right: 32px !important;
  }
  input[type="checkbox"] {
    display: block;
  }
`;

const stateActions = {
  'pending_extra_data': 'Update',
  'partially_saved': 'Update',
  'sales_review': 'Processing',
  'returned': 'Update',
  'rejected': 'Rejected',
  'accepted': 'Accepted',
};

const editableStates = Object.entries(stateActions)
  .filter(([_, label]) => label == 'Update')
  .map(([state, _]) => state);

export default function TableRow(props) {
  const [checked, setChecked] = useState(false);

  const formattedPrice = (prices) => {
    if (prices.length == 1) {
      return `$${prices[0]}`;
    } else {
      const sortedPrices = prices.sort();
      const min = sortedPrices[0];
      const max = sortedPrices.slice(-1);
      return `$${min}-$${max}`;
    }
  };
  const formattedSKU = (skus) => {
    if (skus.length == 1) {
      return skus[0];
    } else {
      return `${skus.length} SKUs`;
    }
  }
  useEffect(() => {
    if (props.row && props.row.rejectionReason) {
      $('[data-toggle="tooltip"]').tooltip();
    }
  })

  useEffect(() => {
    if (checked)
      props.onProductSelected(props);
    else
      props.onProductDeselected(props);
  }, [checked]);

  const handleToggle = () => {
    setChecked(!checked);
  }

  return (
    <tr>
      <Td><input type='checkbox' checked={checked} onChange={handleToggle} /></Td>
      <Td>{moment(props.row.createdAt).format('MMM, DD YYYY • hh:mm')}</Td>
      <Td>{formattedPrice(props.row.salePrice)}</Td>
      <Td>
        <Link onClick={() => props.onProductNameClicked(props)}>
          {props.row.productName}
        </Link>
      </Td>
      <Td>
        {
          props.row.rejectionReason &&
          <span data-toggle="tooltip" data-placement="bottom" title={props.row.rejectionReason}>
            <img src="images/alert.png" />
          </span>
        }
        {formattedSKU(props.row.sku)}
      </Td>
      <Td>
        <Button
          className="btn btn-lg btn-primary table-settings-item"
          onClick={() => props.onUpdateButtonClicked(props)}
          disabled={!editableStates.includes(props.row.state)}
        >
          {stateActions[props.row.state]}
        </Button>
        <RejectButton
          className="btn btn-lg table-settings-item"
          onClick={() => props.onRejectButtonClicked(props)}
          disabled={!editableStates.includes(props.row.state)}
        >
          Reject
        </RejectButton>
        {props.row.state == 'rejected' &&
          <Button
            className="btn btn-lg btn-primary table-settings-item"
            onClick={() => props.onUnrejectButtonClicked(props)}
          >
            Un-reject
          </Button>
        }
      </Td>
    </tr>
  );
};

TableRow.propTypes = {
  row: PropTypes.shape({
    createdAt: PropTypes.string.isRequired,
    salePrice: PropTypes.arrayOf(PropTypes.number).isRequired,
    productName: PropTypes.string.isRequired,
    sku: PropTypes.arrayOf(PropTypes.string).isRequired,
  })
};
