import React, { useState } from 'react';
import styled from 'styled-components';
import Section from './FormSection';

import Form from '../form/Form';
import CheckField from '../form/CheckField';
import HiddenField from '../form/HiddenField';
import TextField from '../form/TextField';
import SelectField from '../form/SelectField';
import Option from '../form/Option';
import { Submit, SubmitLink } from '../form/Buttons';

const Title = styled.h1`
  font-size: 24px;
  line-height: 24px;
  font-weight: 400;
  margin: 0 0 8px;
`;
const Subtitle = styled.h2`
  font-size: 18px;
  line-height: 18px;
  font-weight: 600;
  margin: 0;
`;
const Comment = styled.aside`
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  padding: 24px 0;
  border-bottom: 1px solid #dae0e4;
  color: #777;
`;

const Footer = styled.footer`
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  margin-top: 24px;
  padding: 24px 0 0;
  border-top: 1px solid #dae0e4;
  color: #777;
`;

const categories = [
  "",
  "Add-Ons / Warranties",
  "Apps & Software",
  "Apps & Software / Backup & Storage",
  "Apps & Software / Business",
  "Apps & Software / Other",
  "Apps & Software / Education",
  "Apps & Software / Leisure",
  "Apps & Software / Productivity",
  "Apps & Software / Security",
  "Apps & Software / Utilities",
  "Other",
  "Electronics & Gadgets / Other",
  "Electronics & Gadgets / Computers",
  "Electronics & Gadgets / Connected Devices",
  "Electronics & Gadgets / Gaming & Toys",
  "Electronics & Gadgets / Charging",
  "Electronics & Gadgets / Sound",
  "Gift Cards",
  "Lifestyle / Accessories",
  "Lifestyle / Other",
  "Lifestyle / Games & Toys",
  "Lifestyle / Beauty",
  "Lifestyle / Health & Fitness",
  "Lifestyle / Home",
  "Lifestyle / Kitchen",
  "Lifestyle / Outdoors",
  "Online Courses / Business & Finance",
  "Online Courses / Other",
  "Online Courses / Development",
  "Online Courses / IT & Security",
  "Online Courses / Languages",
  "Online Courses / Personal Development",
  "Store Credit",
  "Electronics & Gadgets / Tablets",
  "Giveaways",
  "Memberships / Club Stores",
  "Memberships / Health & Fitness",
  "Memberships / Travel",
  "Memberships / Other"
];

export default function ProductForm({ item, validateData, onSubmit }) {
  return (
    <div className="row">
      <div className="col-lg-12">
        <section className="row">
          <div className="col-xs-12">
            <Form
              className="panel panel-default"
              data={item.extraFields}
              onSubmit={(data, value) => onSubmit(data, value)}
            >
              <Title>Product Details</Title>
              <Subtitle>{item.productName}</Subtitle>
              <Comment>All fields are required unless marked with an asterisk (*)</Comment>
              <HiddenField name="reviewQueueItemId" value={item.id} />
              <HiddenField name="saleDraftId" value={item.saleDraftId} />
              <Section title="Properties">
                <TextField name="brand" label="Brand" placeholder="What's the brand of this product?" />
                <SelectField name="category" label="Category" placeholder="What's the product category?">
                  {categories.map((category, i) => <Option value={category} key={`category-${i}`} label={category} />)}
                </SelectField>
                <TextField name="payout" label="Payout (%)" placeholder="What's the payout of this product?" />
                <TextField name="leadTime" label="Lead Time" placeholder="What's the time between order and tracking?" />
                <TextField name="videoUrl" label="Product Video URL" placeholder="Insert a URL to a video about this product" optional />
              </Section>
              <Section title="Shipping" tagline="All values will be rounded to one decimal point (e.g. 10.1) and should be greater than or equal to 0.1" >
                <TextField name="productWeight" label="Product Weight (Ounces/Oz.)" placeholder="What is the product weight?" />
                <TextField name="productHeight" label="Product Height (Inches)" placeholder="What is the product height?" />
                <TextField name="productWidth" label="Product Width (Inches)" placeholder="What is the product width?" />
                <TextField name="productDepth" label="Product Depth (Inches)" placeholder="What is the product depth?" />
                <TextField name="packageHeight" label="Package Height (Inches)" placeholder="What is the package height?" />
                <TextField name="packageWidth" label="Package Width (Inches)" placeholder="What is the package width?" />
                <TextField name="packageDepth" label="Package Depth (Inches)" placeholder="What is the package depth?" />
                <SelectField name="country" label="Country/Territory" placeholder="From what country does this product ship?">
                  <Option value="" label="" />
                  <Option value="us" label="United States" />
                </SelectField>
                <TextField name="originPostalCode" label="Postal Code of Origin" placeholder="From what postal code does this product ship?" />
                <CheckField name="contiguousUS" label="I ship my products only to addresses in the contiguous 48 states" />
              </Section>
              <Footer>
                <Submit
                  label="Submit for Review"
                  validator={(data) => validateData(data)} />
                <SubmitLink
                  className="draft-button"
                  label="Save as Draft"
                  validator={(_) => true} />
              </Footer>
            </Form>
          </div>
        </section>
      </div>
    </div>
  );
};
