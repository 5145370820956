import React, { useState, useEffect } from 'react';
import FiltersForm from './FiltersForm';
import styled from 'styled-components';

const Action = styled.button.attrs(() => ({
  className: 'btn btn-danger btn-block',
}))`
  margin-top: 5px;
`;

export default function SideBar({
  state,
  enableBulkActions,
  onFiltersFormSubmitted,
  onRejectAll
}) {
  const [disableBulkActions, setDisableBulkActions] = useState(!enableBulkActions);

  useEffect(() => {}, [state]);

  useEffect(() => {
    setDisableBulkActions(!enableBulkActions)
  }, [enableBulkActions]);

  return (
    <aside className='col-sm-2'>
      <FiltersForm
        onFormSubmit={(filters) => onFiltersFormSubmitted(filters)}
      />
      {
        state == 'pending_extra_data' &&
        <Action
          disabled={disableBulkActions}
          onClick={onRejectAll}
        >
          Reject All
        </Action>
      }
    </aside>
  );
}
