import React from 'react';
import PropTypes from 'prop-types';
import TableRow from './TableRow';

export default function Table(props) {
  return (
    <table className="table table-hover table-condensed stack-table">
      <thead>
        <tr>
          <th className="hidden-xs text-nowrap"></th>
          <th className="hidden-xs text-nowrap">Created On</th>
          <th className="hidden-xs text-nowrap">Sale Price</th>
          <th className="hidden-xs column-width-50 text-nowrap">Product</th>
          <th className="hidden-xs ext-nowrap">SKU</th>
          <th className="hidden-xs ext-nowrap"></th>
        </tr>
      </thead>

      <tbody>
        {props.data && props.data.map((row, i) => (
          <TableRow
            key={`${row.sku}-${i}`}
            row={row}
            onUpdateButtonClicked={props.onUpdateButtonClicked}
            onProductNameClicked={props.onProductNameClicked}
            onRejectButtonClicked={props.onRejectButtonClicked}
            onUnrejectButtonClicked={props.onUnrejectButtonClicked}
            onProductSelected={props.onProductSelected}
            onProductDeselected={props.onProductDeselected}
          />
        ))}
      </tbody>
    </table>
  );
};

Table.propTypes = {
  data: PropTypes.arrayOf(TableRow.propTypes.row)
};

Table.defaultProps = {
  data: []
}
