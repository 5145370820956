import React, { useState, useEffect } from 'react';
import PageHeader from '../PageHeader';
import Table from './Table';
import Filters from './TableFilters';
import Paginator from './Paginator';
import ProductForm from './ProductForm';
import RejectionConfirmation from './RejectionConfirmation';
import ItemDetail from './ItemDetail';
import Modal from './Modal';
import Panel from '../form/Panel';
import UnrejectionConfirmation from './UnrejectionConfirmation';
import BulkRejectionConfirmation from './BulkRejectionConfirmation';
import SideBar from '../form/SideBar';

let filters = [
  { label: 'To Review', state: 'pending_extra_data' },
  { label: 'Reviewing', state: 'partially_saved' },
  { label: 'Processing', state: 'sales_review' },
  { label: 'Rejected', state: 'rejected' },
  { label: 'Accepted', state: 'accepted' },
];

export default function ReviewQueue({ partnerApi, currentPartner, notifier }) {
  const currentPartnerJson = JSON.parse(currentPartner());
  const fetchItemsUrl = 'review_queue_items';
  const updateReviewQueueUrl = 'review_queue_items/<id>';

  const REQUIRED_FIELDS = [
    'brand', 'category', 'payout', 'leadTime',
    'productWeight', 'productHeight', 'productWidth',
    'productDepth', 'packageHeight', 'packageWidth',
    'packageDepth', 'country', 'originPostalCode'
  ];
  const INTEGER = /^\d+$/;
  const FLOAT = /^\d+(\.\d+)?$/;
  const FIELDS_TYPES = {
    leadTime: INTEGER,
    productWeight: FLOAT,
    productHeight: FLOAT,
    productWidth: FLOAT,
    productDepth: FLOAT,
    packageHeight: FLOAT,
    packageWidth: FLOAT,
    packageDepth: FLOAT
  }
  const FLOAT_REGEX = /^(\-|\+)?([0-9]+(\.[0-9]+)?|Infinity)$/;
  const CUSTOM_VALIDATION_FIELDS = [
    'payout', 'productWeight', 'productHeight',
    'productWidth', 'productDepth', 'packageHeight',
    'packageWidth', 'packageDepth'
  ];

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalResultsCount, setTotalResultsCount] = useState(0);
  const [currentFilter, setCurrentFilter] = useState(null);
  const [extraFilters, setExtraFilters] = useState(null);
  const pageSize = 25;

  const [editing, setEditing] = useState(false);
  const [showing, setShowing] = useState(false);
  const [rejecting, setRejecting] = useState(false);
  const [unrejecting, setUnrejecting] = useState(false);
  const [bulkRejecting, setBulkRejecting] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);

  useEffect(() => { fetchData(); }, [currentPage, currentFilter, extraFilters]);

  const validateProductAttributes = (field, value) => {
    if (!FLOAT_REGEX.test(value)) return false;

    const parsedValue = Number(value);

    if (field == "payout") {
      return parsedValue > 0 && parsedValue <= 100;
    } else {
      return parsedValue >= 0.1;
    }
  };

  const fetchData = () => {
    const sku = extraFilters ? extraFilters.sku : "";
    const product = extraFilters ? extraFilters.product : "";
    const path = `${fetchItemsUrl}?state=${currentFilter && currentFilter.state}&sku=${sku}&product=${product}&page=${currentPage}&pageSize=${pageSize}`;
    partnerApi.request({ path: path, method: 'get' })
      .then(({ data }) => {
        setCurrentPage(data.page),
          setTotalResultsCount(data.totalResultsCount),
          setData(data.items)
      })
      .catch((error) => console.log(error));
  }

  const changeFilterHandler = (filter) => {
    setCurrentFilter(filter);
  }

  const changePageHandler = (page) => {
    setCurrentPage(page);
  }

  const showModalFormFor = (item) => {
    setEditing(item.row);
  }

  const showProductDetailsFor = (item) => {
    setShowing(item.row);
  }

  const showRejectModalFormFor = (item) => {
    setRejecting(item.row);
  }

  const showUnrejectModalFormFor = (item) => {
    setUnrejecting(item.row);
  }

  const showBulkRejectModal = () => {
    setBulkRejecting(selectedProducts);
  }

  const selectProduct = (item) => {
    const id = item.row.id;

    if (!selectedProducts.includes(id)) {
      setSelectedProducts(selectedProducts.concat([id]));
    }
  }

  const deselectProduct = (item) => {
    setSelectedProducts(selectedProducts.filter((id) => id != item.row.id));
  }

  const submitData = (data, submitLabel) => {
    let { reviewQueueItemId, ...extraFields } = data;
    let path = updateReviewQueueUrl.replace('<id>', reviewQueueItemId);
    const draft = submitLabel === 'Save as Draft';
    if (draft && Object.keys(extraFields).length > 0)
      extraFields = { ...extraFields, draft };
    if (Object.keys(extraFields).length > 0) {
      partnerApi.request({ method: 'patch', path: path, data: extraFields })
        .then((_response) => {
          notifier.success(
            draft ?
              'Your data was successfuly saved. You can keep editing if from the <b>Reviewing</b> tab.' :
              'Your product was submitted. Wait while your account manager review it.'
          );
          if (currentFilter != null)
            changeFilterHandler(currentFilter);
          setEditing(false)
        })
        .catch((error) => console.log(error));
    } else {
      setEditing(false);
    }
  }

  const validateData = (extraFields) => {
    let errors = REQUIRED_FIELDS.reduce((errors, field) => {
      if (extraFields[field] == undefined || extraFields[field] == null || extraFields[field].toString().trim() == '')
        errors[field] = 'blank';
      return errors;
    }, {});

    if (Object.keys(errors).length > 0) {
      notifier.error('Some required fields were left blank. Please fill in the fields highlighted in red.');
      return errors;
    }

    errors = Object.entries(extraFields).reduce((errors, [field, value]) => {
      if (FIELDS_TYPES[field] && !value.match(FIELDS_TYPES[field]))
        errors[field] = 'invalid';
      return errors;
    }, {});

    if (Object.keys(errors).length > 0) {
      notifier.error('Some fields contain invalid data. Please review the fields highlighted in red.');
      return errors;
    }

    errors = Object.entries(extraFields).reduce((errors, [field, value]) => {
      if (CUSTOM_VALIDATION_FIELDS.includes(field) && !validateProductAttributes(field, value))
        errors[field] = 'invalid';
      return errors;
    }, {});

    if (Object.keys(errors).length > 0) {
      notifier.error('Some fields contain invalid data. Please review the fields highlighted in red.');
      return errors;
    }

    return true;
  };

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="row">
          <PageHeader partnerName={currentPartnerJson.name} title="Review Queue" />
        </div>

        <SideBar
          state={currentFilter ? currentFilter.state : ''}
          enableBulkActions={selectedProducts.length > 0}
          onFiltersFormSubmitted={setExtraFilters}
          onRejectAll={() => showBulkRejectModal()}
        />

        <article className="col-sm-10">
          <Filters filters={filters} onFilterChanged={(filter) => changeFilterHandler(filter)} />

          <Panel>
            <Table
              data={data}
              onProductNameClicked={(item) => showProductDetailsFor(item)}
              onUpdateButtonClicked={(item) => showModalFormFor(item)}
              onRejectButtonClicked={(item) => showRejectModalFormFor(item)}
              onUnrejectButtonClicked={(item) => showUnrejectModalFormFor(item)}
              onProductSelected={(item) => selectProduct(item)}
              onProductDeselected={(item) => deselectProduct(item)}
            />
            {data && data.length > 0 ?
              <div style={{ padding: "0 25px" }}>
                <Paginator
                  currentPage={currentPage}
                  pageSize={pageSize}
                  totalResultsCount={totalResultsCount}
                  onPageChanged={(page) => changePageHandler(page)}
                />
              </div>
              : <div style={{ padding: "25px 0", display: "flex", justifyContent: "center" }}>
                No items to review
              </div>
            }
          </Panel>
        </article>
      </div>
      {
        editing &&
        <Modal onCloseClicked={() => setEditing(false)}>
          <ProductForm
            item={editing}
            currentPartner={currentPartnerJson.name}
            validateData={(data) => validateData(data)}
            onSubmit={(data, submitLabel) => submitData(data, submitLabel)}
          />
        </Modal>
      }
      {
        showing &&
        <Modal onCloseClicked={() => setShowing(false)}>
          <ItemDetail
            item={showing}
            currentPartner={currentPartnerJson.name}
            partnerApi={partnerApi}
          />
        </Modal>
      }
      {
        rejecting &&
        <Modal onCloseClicked={() => setRejecting(false)}>
          <RejectionConfirmation
            item={rejecting}
            partnerApi={partnerApi}
          />
        </Modal>
      }
      {
        unrejecting &&
        <Modal onCloseClicked={() => setUnrejecting(false)}>
          <UnrejectionConfirmation
            item={unrejecting}
            partnerApi={partnerApi}
          />
        </Modal>
      }
      {
        bulkRejecting &&
        <Modal onCloseClicked={() => setBulkRejecting(false)}>
          <BulkRejectionConfirmation
            items={selectedProducts}
            partnerApi={partnerApi}
          />
        </Modal>
      }
    </div >
  );
};
