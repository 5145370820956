import React from 'react';
import styled from 'styled-components';

const Label = styled.label`
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  flex-flow: column nowrap;
`;

export default function({ className, text, children, optional }) {
  return (
    <Label className={className}>
      {text}{optional && '*'}
      {children}
    </Label>
  );
};
