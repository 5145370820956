import React from 'react';
import styled from 'styled-components';

const Section = styled.section`
  display: flex;
  flex-flow: column nowrap;

  & > h1 {
    font-size: 18px;
    font-weight: 600;
    line-height: 18px;
    padding: 0;
    margin: 24px 0;
  }

  & > h2 {
    font-size: 16px;
    line-height: 16px;
    font-weight: 400;
  }
`;

export default function FormSection(props) {
  return (
    <Section>
      <h1>{props.title}</h1>
      {props.tagline && <h2>{props.tagline}</h2>}
      {props.children}
    </Section>
  );
};

