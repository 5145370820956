import React, { useState } from 'react';
import styled from 'styled-components';
import ItemDetailSection from './ItemDetailSection';
import ItemDetailRow from './ItemDetailRow';
import ItemDetailImage from './ItemDetailImage';

const Title = styled.h1`
  font-size: 24px;
  line-height: 24px;
  font-weight: 400;
  margin: 0px 0px 8px 0px;
`;

const Subtitle = styled.h2`
  font-size: 18px;
  line-height: 18px;
  font-weight: 600;
  margin: 0px;
`;

const Wrapper = styled.div`
  max-height: 95vh;
  overflow-y: auto;
  padding: 32px;
  display: flex;
  width: 544px;
  flex-flow: column nowrap;
`;

const PROPERTY_FIELDS = [
  { label: "Name", key: "name" },
  { label: "Category", key: "type" },
  { label: "Status", key: "status", formatter: statusFormatter }
];

const CONTENT_FIELDS = [
  { label: "Description", key: "description" }
];

const VARIANT_FIELDS = [
  { label: "MSRP", key: "price" },
  { label: "Product Price", key: "unit_cost" },
  { label: "SKU", key: "sku" }
];

function statusFormatter(value) {
  if (value == 'active'){
    return 'Live';
  } else {
    return 'Not Live';
  }
}

const collectImages = (data) => {
  const init = (data.images || []).map((image, i) => ({ ...image, label: `Image ${i + 1}` }));
  return (data.variants || [])
    .reduce(
      (images, variant) => images.concat(variant.images.map((image, i) => ({ ...image, label: `${variant.title} image ${i + 1}` }))),
      init);
};

export default function ItemDetail({ item, currentPartner, partnerApi }) {
  const showReviewQueueUrl = 'review_queue_items/<id>';
  const [data, setData] = useState([]);

  let path = showReviewQueueUrl.replace('<id>', item.id);

  partnerApi.request({ method: 'get', path: path })
    .then(({ data }) => setData(data))
    .catch((error) => console.log(error));

  return (
    <div className="row">
      <div className="col-lg-12">
        <section className="row">
          <div className="col-xs-12">
            <Wrapper className="panel panel-default">
              <Title>Product Details</Title>
              <Subtitle>{currentPartner}</Subtitle>
              <ItemDetailSection title="Properties">
                { PROPERTY_FIELDS.map(({key, label, formatter}, i) => <ItemDetailRow label={label} value={data[key]} key={i} formatter={formatter}/>) }
              </ItemDetailSection>
              <ItemDetailSection title="Content">
                { CONTENT_FIELDS.map(({key, label}, i) => <ItemDetailRow label={label} value={data[key]} vertical={true} key={i}/>) }
              </ItemDetailSection>
              {
                (data.variants || []).map((variant, i) => (
                    <ItemDetailSection title={`Variant ${variant.title}`}>
                      { VARIANT_FIELDS.map(({key, label}, i) => <ItemDetailRow label={label} value={variant[key]} key={i}/>) }
                    </ItemDetailSection>
                  ) 
                )
              }
              {
                <ItemDetailSection title="Images" gallery={true}>
                  { collectImages(data).map(({label, src}, i) => <ItemDetailImage label={label} value={src} key={i}/>) }
                </ItemDetailSection>
              } 
            </Wrapper>
          </div>
        </section>
      </div>
    </div>
  );
};
