import React from 'react';
import PageHeader from '../PageHeader';

export default function Edit(props) {
  const currentPartner = JSON.parse(props.currentPartner());

  const name = "Foobar";

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="row">
          <PageHeader partnerName={currentPartner.name} title={`Edit ${name}`} />
        </div>

        <section className="row">
          <article className="col-xs-12">
            <div className="panel panel-default">
              <ProductForm />
            </div>
          </article>
        </section>
      </div>
    </div>
  );
};
