import React from 'react';
import PropTypes from 'prop-types';

export default function Paginator({ currentPage, totalResultsCount, pageSize, onPageChanged }) {
  let visiblePages = (currentPage, totalCount, pageSize) => {
    const pageCount = Math.ceil(totalCount / pageSize);
    const regionSize = Math.min(pageCount, 5);
    const itemsAfter = regionSize - 1 - Math.min(Math.floor(regionSize / 2), pageCount - currentPage);
    const firstPage = Math.max(currentPage - itemsAfter, Math.max(1, currentPage - regionSize));

    let visiblePages = Array(regionSize).fill(0).map((_, idx) => firstPage + idx);
    if (firstPage > 1) visiblePages.splice(0, 0, null);
    if (firstPage + regionSize <= pageCount) visiblePages.push(null);

    return visiblePages;
  };

  const linkClickHandler = (fn) => {
    return (ev) => {
      ev.preventDefault();
      return onPageChanged(fn());
    }
  };

  return (
    <ul className="pagination">
      <li className={`page-item ${currentPage == 1 && 'disabled'}`}>
        <a className="page-link" href="#" onClick={linkClickHandler(() => currentPage - 1)}>Previous</a>
      </li>
      {
        visiblePages(currentPage, totalResultsCount, pageSize)
          .map((pageNumber, i) => <li key={`paginator-page-${i}-${pageNumber}`} className={`page-item ${pageNumber == null && 'disabled'} ${currentPage == pageNumber && 'active'}`}>
            <a className="page-link" href="#" onClick={linkClickHandler(() => pageNumber)}>{pageNumber || '...'}</a>
          </li>)
      }
      <li className={`page-item ${currentPage == Math.ceil(totalResultsCount / pageSize) && 'disabled'}`}>
        <a className="page-link" href="#" onClick={linkClickHandler(() => currentPage + 1)}>Next</a>
      </li>
    </ul>
  );
};

Paginator.propTypes = {
  currentPage: PropTypes.number,
  totalResultsCount: PropTypes.number,
  pageSize: PropTypes.number,
};

Paginator.defaultProps = {
  pageSize: 25
};
