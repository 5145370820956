import React, { useContext } from 'react';
import styled from 'styled-components';
import Label from './Label';
import { FormContext } from './Form';

const Select = styled.select`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding: 16px;
  border: 1px solid #dddee0;
  border-radius: 3px;
  margin: 8px 0 24px;
  background-color: #fff;

  &.error { border-color: #F21F1D; }
  &::-webkit-input-placeholder { color: #dae0e4; }
  &:-ms-input-placeholder { color: #dae0e4; }
  &::placeholder { color: #dae0e4; }
`;

export default function SelectField({ children, name, label, optional }) {
  const { data, errors, updateKey } = useContext(FormContext);
  return (
    <Label text={label} optinal={optional}>
      <Select
        className={errors[name] ? 'error' : ''}
        name={name}
        value={data[name]}
        onChange={ev => updateKey(name, ev.target.value)}
      >
        {children}
      </Select>
    </Label>
  );
};
