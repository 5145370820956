import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const FiltersList = styled.ul`
  list-style-type: none;
  padding: 0;
  display: flex;
`;

const ListItem = styled.li`
  color: ${props => (props.selected ? '#31343E' : '#999')};
  margin-right: 24px;

  button {
    ${props => props.selected && css`box-shadow: inset 0px -2px 0px #4B65D5;`}
    color: inherit;
    padding: 8px 0;
    appearance: none;
    background: transparent;
    border: none;
    outline: none;
  }
`;

export default function TableFilters(props) {
  const [currentFilter, setCurrentFilter] = useState(props.filters[0] || {});

  React.useEffect(() => {
    props.onFilterChanged(currentFilter);
  }, [currentFilter]);

  return (
    <FiltersList>
      {
        (props.filters || []).map(filter =>
          <ListItem key={filter.state} selected={currentFilter == filter}>
            <button href="#" onClick={() => setCurrentFilter(filter)}>
              {filter.label}
            </button>
          </ListItem>
        )
      }
    </FiltersList>
  );
};

TableFilters.propTypes = {
  onFilterChanged: PropTypes.func,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired
    })
  )
};

TableFilters.defaultProps = {
  onFilterChanged: () => { },
  filters: []
};
