import React, { useState } from 'react';
import styled from 'styled-components';
import Button from '../form/Buttons';

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  background-color: #c7ccd6;
  display: flex;
  justify-content: center;
  align-items: center;
  & > .wrapper {
    position: relative;
  }
`;

export const CloseButton = styled(Button)`
  background-color: transparent;
  border-radius: 50%;
  color: #0AAFC7;
  width: 15px;
  height: 15px;
  box-sizing: content-box;
  padding: 10px;
  position: absolute;
  top: 20px;
  right: 20px;
  opacity: .6;
  outline: none;
  &:hover {
    background-color: #fff;
    border-color: #dae0e4;
    opacity: 1;
  }
  &:active {
    background-color: #dae0e4;
  }
`;

export default function({ children, onCloseClicked }) {
  return (
    <Modal className="modal">
      <div className="wrapper">
        {children}
        <CloseButton label="X" title="Close without saving" onClick={() => { onCloseClicked && onCloseClicked() }} />
      </div>
    </Modal>
  );
};
