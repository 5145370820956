import React from 'react';
import styled from 'styled-components';

const Title = styled.h1`
  font-size: 24px;
  line-height: 24px;
  font-weight: 400;
  margin: 0 0 8px;
`;

const Subtitle = styled.h2`
  font-size: 18px;
  line-height: 18px;
  font-weight: 600;
  margin: 0;
`;

const Wrapper = styled.div`
  max-height: 95vh;
  overflow-y: auto;
  padding: 32px;
  display: flex;
  width: 544px;
  flex-flow: column nowrap;
`;

const Button = styled.button`
  color: #fff;
  background-color: #0AAFC7;
  border-color: #099AAF;
  font-weight: 600;
  margin-top: 20px;
  width: 200px;
`;

const Div = styled.div`
  margin-top: 20px;
  text-align: center;
`;

function submitRejection(partnerApi, reviewQueueItemIds) {
  let path = 'review_queue_items/bulk_reject';

  partnerApi.request({
        path: path,
        method: 'patch',
        data: { ids: reviewQueueItemIds }
    })
    .then((_response) => {
      window.location.reload();
    })
    .catch((error) => console.log(error));
};

export default function BulkRejectionConfirmation({ items, partnerApi }) {
  return (
    <div className="row">
      <div className="col-lg-12">
        <section className="row">
          <div className="col-xs-12">
            <Wrapper className="panel panel-default">
              <Title>You are about to reject {items.length} product(s)</Title>
              <Subtitle>Are you sure you want to reject all products?</Subtitle>
              <Div>
                <Button className="btn btn-primary" onClick={() => submitRejection(partnerApi, items)}>
                  Ok
                </Button>
              </Div>
            </Wrapper>
          </div>
        </section>
      </div>
    </div>
  );
};
