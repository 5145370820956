import React from 'react';
import styled from 'styled-components';

const Row = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 0px;
`;

const Label = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  color: #777777;
`;

const Image = styled.img`
  max-width: 100%;
`;

export default function ItemDetailImage({ label, value }) {
  return (
    <Row>
      <Label>{label}</Label>
      <Image src={value} />
    </Row>
  );
};
