import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import BaseLabel from './Label';
import { FormContext } from './Form';

const InlineLabel = styled(BaseLabel)`
  font-weight: 400 !important;
  flex-direction: row-reverse;
  align-items: center;
`;

const Input = styled.input`
  border: 1px solid #dddee0;
  margin: 0 8px !important;
  background-color: #fff;
  display: inline !important;

  &.error { border-color: #F21F1D; }
`;

export default function CheckInput({ label, name }) {
  const { data, updateKey } = useContext(FormContext);
  return (
    <InlineLabel text={label}>
      <Input
        type="checkbox"
        name={name}
        checked={data[name] || false}
        onChange={ev => updateKey(name, ev.target.checked)}
      />
    </InlineLabel>
  );
};

CheckInput.propTypes = {
  row: PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })
};
