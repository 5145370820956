import React from 'react';
import PropType from 'prop-types';
import styled from 'styled-components';

const Title = styled.h2`
  font-size: 18px;
  line-height: 18px;
  font-weight: 600;
  margin: 0;
`;

const Divisor = styled.hr`
  margin: 24px 0;
  color: #777;
`;

const Wrapper = styled.div`
  display: flex;
  flex-flow: ${props => (props.gallery ? 'row wrap' : 'column')};
  img {
    margin-right: 5px;
  }
`;

export default function ItemDetailSection({ title, children, gallery }) {
  return (
    <div>
      <Divisor/>
      <Title>{title}</Title>
      <Wrapper gallery={gallery}>
        {children}
      </Wrapper>
    </div>
  );
};

ItemDetailSection.propTypes = {
  title: PropType.string.isRequired,
  gallery: PropType.bool
};

ItemDetailSection.defaultProps = {
  gallery: false
}