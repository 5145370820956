import React from 'react';
import styled from 'styled-components';

const BasePanel = styled.div`
  padding-bottom: 0;
`;

export default function Panel({ children }) {
  return (
    <BasePanel className="panel panel-default">
      {children}
    </BasePanel>
  );
};
