import React, { useState } from 'react';
import styled from 'styled-components';

const BaseForm = styled.div`
  max-height: 95vh;
  overflow-y: auto;
  padding: 32px;
  display: flex;
  max-width: 544px;
  flex-flow: column nowrap;
`;

export const FormContext = React.createContext({
  data: {},
  updateKey: (_key, _value) => ({})
});

export default function Form({ children, data, className, valid, onSubmit }) {
  const [internalData, setData] = useState(data);
  const [errors, setErrors] = useState({});
  const updateKey = (key, value) => {
    setErrors({ ...errors, [key]: null })
    setData({ ...internalData, [key]: value })
  };

  const submit = (data, validator, submitLabel) => {
    let errors = null;
    let valid = true;

    if (validator) {
      errors = validator(data);
      valid = (errors == true || errors == null);
      if (!valid) {
        errors = Object
          .entries(errors)
          .reduce((errors, [field, message]) => ({ ...errors, [field]: message }), {});
        setErrors(errors)
      }
    }
    valid && onSubmit && onSubmit(data, submitLabel);
  }

  return (
    <BaseForm className={className}>
      <FormContext.Provider value={{ data: internalData, errors, updateKey, submit }}>
        {children}
      </FormContext.Provider>
    </BaseForm>
  );
};
