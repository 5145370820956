import React from 'react';
import PropTypes from 'prop-types';

export default function Option({ value, label }) {
  return (
    <option value={value}>{label}</option>
  );
};

Option.propTypes = {
  row: PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })
};

