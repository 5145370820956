/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
import React from 'react';
import { render } from 'react-dom';
import RutterLink from '../components/RutterLink'
import ListReviewQueueItems from '../components/ReviewQueue/Index'

var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

window.RutterLink = RutterLink;
window.ReviewQueue = ListReviewQueueItems;

window.mountReactApp = function(componentClass, props, mountPoint) {
  var component = React.createElement(componentClass, props, null)
  render(component, document.querySelector(mountPoint))
}
