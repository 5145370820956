import React, { useState } from 'react';
import PropTypes from 'prop-types';

export default function FiltersForm(props) {
  const [product, setProduct] = useState("");
  const [sku, setSku] = useState("");

  const onSubmit = (event) => {
    event.preventDefault();
    props.onFormSubmit({
      product: product,
      sku: sku
    });
  }

  return (
    <form onSubmit={onSubmit}>
      <div className="form-group">
        <label htmlFor="sku">SKU</label>
        <input
          id="sku"
          type="text"
          className="form-control"
          placeholder="SKU"
          onChange={(e) => setSku(e.target.value)}
          />
      </div>
      <div className="form-group">
        <label htmlFor="product">Product Name</label>
        <input
          id="product"
          type="text"
          className="form-control"
          placeholder="Product Name"
          onChange={(e) => setProduct(e.target.value)}
        />
        </div>
      <button className="btn btn-primary btn-block" type="submit">Filter</button>
    </form>
  );
}

FiltersForm.propTypes = {
  onFormSubmit: PropTypes.func
};

FiltersForm.defaultProps = {
  onFormSubmit: () => { }
};
