import React from 'react';
import PropTypes from 'prop-types';

export default function PageHeader(props) {
  return (
    <header className="col-lg-12">
      <h2>
        <div>
          <span className="ng-binding">{props.partnerName} / </span>
          <strong className="text-primary">{props.title}</strong>
        </div>
      </h2>
      <hr />
    </header>
  );
}

PageHeader.propTypes = {
  partnerName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}
