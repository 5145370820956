import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormContext } from './Form';

const BaseButton = styled.button`
  width: 100%;
  padding: 20px;
  border: 1px solid transparent;
  box-sizing: border-box;
  border-radius: 3px;
`;

export default function Button({ className, label, children, onClick }) {
  return (
    <BaseButton className={className} onClick={(ev) => { onClick && onClick(ev) }}>
      {label ? label : children}
    </BaseButton>
  );
};

Button.propTypes = {
  row: PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })
};

export const PrimaryButton = styled(Button)`
  color: #fff;
  background-color: #0AAFC7;
  border-color: #099AAF;
  font-weight: 600;
`;

export const LinkButton = styled(Button)`
  background-color: #fff;
  color: #0AAFC7;
  font-weight: 600;
  border-color: transparent;
`;

export function Submit({ children, className, label, validator }) {
  const { data, submit } = useContext(FormContext);

  return (
    <PrimaryButton className={className} onClick={() => submit(data, validator, label)}>
      {label ? label : children}
    </PrimaryButton>
  );
};

export const SubmitLink = styled(Submit)`
  background-color: #fff;
  color: #0AAFC7;
  font-weight: 600;
  border-color: transparent;
`;
