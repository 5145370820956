import React, { useState } from "react";
import { useRutterLink } from "react-rutter-link";
import styled from 'styled-components';

const Button = styled.button`
  width: 100%;
`;

const Title = styled.h4`
  max-width: 550px;
`;

const PUBLIC_KEY = process.env.RUTTER_PUBLIC_KEY;

export default function RutterLink({ currentPartner, rootScope, partnerApi, notifier, isConnected }) {
  const [connected, setConnected] = useState(isConnected || false);
  const config = {
    publicKey: PUBLIC_KEY,
    onSuccess: (publicToken) => {
      let path = "rutter/exchange";
      let data = { public_token: publicToken };
      partnerApi.request({ method: 'post', path: path, data: data })
        .then((_response) => {
          setConnected(true);
          let currPartner = JSON.parse(currentPartner());
          let tmpPartner = _.find(rootScope.main.user.partners[currPartner.role], (p) => parseInt(p.id) == parseInt(currPartner.id));
          tmpPartner.seller_tokens['rutter'] = true;
          tmpPartner.stale = true;
        })
        .catch((error) => {
          console.log(error)
          notifier.error("Something went wrong. Please let your account manager know about it.");
        });
    }
  };
  const { open } = useRutterLink(config);

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="row">
          <header className="col-lg-12">
            <h3>{connected ? 'Shopify API' : 'Connect to Shopify'}</h3>
            <Title>{connected ? "You're already connected" : "Import your product catalogue on Shopify with a single click. We use Rutter API to retrieve Shopify data."}</Title>
          </header>
        </div>
        <div className="row article-rss-feed">
          <div className="col-lg-12">
            {
              !connected &&
              <Button className="btn btn-primary" onClick={() => open({ platform: 'SHOPIFY' })}>
                Connect to Shopify
              </Button>
            }
          </div>
        </div>
      </div>
    </div>
  );
}
