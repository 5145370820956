import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { FormContext } from './Form';

export default function HiddenInput({ name, value }) {
  const { data, updateKey } = useContext(FormContext);
  useEffect(() => {
    if (data[name] == undefined || data[name] != value)
      updateKey(name, value);
  })

  return (
    <div />
  );
};

HiddenInput.propTypes = {
  row: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
  })
};
