import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Label from './Label';
import { FormContext } from './Form';

const Input = styled.input`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding: 16px;
  border: 1px solid #dddee0;
  border-radius: 3px;
  margin: 8px 0 24px;
  background-color: #fff;

  &.error { border-color: #F21F1D; }
  &::-webkit-input-placeholder { color: #dae0e4; }
  &:-ms-input-placeholder { color: #dae0e4; }
  &::placeholder { color: #dae0e4; }
`;

export default function TextInput({ name, label, optional, placeholder }) {
  const { data, errors, updateKey } = useContext(FormContext);
  return (
    <Label text={label} optional={optional} >
      <Input
        type="text"
        className={errors[name] ? 'error' : ''}
        name={name}
        placeholder={placeholder}
        value={data[name] || ""}
        onChange={ev => updateKey(name, ev.target.value)}
      />
    </Label>
  );
};

TextInput.propTypes = {
  row: PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    optional: PropTypes.bool,
  })
};

TextInput.defaultProps = {
  placeholder: '',
  optional: false,
};
