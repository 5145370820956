import React from 'react';
import PropType from 'prop-types';
import styled from 'styled-components';

const Row = styled.div`
  display: flex;
  flex-direction: ${props => (props.vertical ? 'column' : 'row')};
  justify-content: space-between;
  padding: 16px 0px 0px 0px;
`;

const RowTitle = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  color: #777777;
`;

const RowData = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  color: #31343E;
  padding-top: ${props => (props.vertical ? '8px' : '0px')};
`;

export default function ItemDetailRow({ label, value, formatter, vertical }) {
  return (
    <Row vertical={vertical}>
      <RowTitle>{label}</RowTitle>
      <RowData vertical={vertical}>{formatter(value)}</RowData>
    </Row>
  );
};

ItemDetailRow.propTypes = {
  label: PropType.string.isRequired,
  value: PropType.any,
  formatter: PropType.func,
  vertical: PropType.bool
};

ItemDetailRow.defaultProps = {
  formatter: (value) => value,
  vertical: false
}